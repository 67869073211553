<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx ali-c">
          <el-row>
            <el-input
              placeholder="订单编号"
              class="search-input"
              clearable
              v-model="searchObj.orderNumber"
            ></el-input>
            <el-input
              placeholder="手机号"
              clearable
              class="search-input ml-15"
              v-model="searchObj.phone"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              class="ml-15"
              @click="initData"
              >查询</el-button
            >
            <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
              >重置</el-button
            >
          </el-row>
        </div>
      </el-col>
    </el-row>

    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 退款状态 -->
          <el-tabs v-model="searchObj.refundStatus" @tab-click="changeTab">
            <el-tab-pane label="待审核" name="0"></el-tab-pane>
            <el-tab-pane label="已审核" name="1"></el-tab-pane>
          </el-tabs>
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column
              label="订单编号"
              prop="cateringOrderCode"
              width="200px"
            ></el-table-column>
            <el-table-column label="客户昵称" prop="userName"></el-table-column>
            <el-table-column label="手机号码" prop="phone"></el-table-column>
            <el-table-column
              label="所属门店"
              prop="storeName"
            ></el-table-column>
            <el-table-column label="订单金额">
              <template slot-scope="scope">
                <span>￥{{ scope.row.refundPrice || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="惠购券">
              <template slot-scope="scope">
                <span>￥{{scope.row.hgoCouponReduce || 0}}</span>
              </template>
            </el-table-column>
            <el-table-column label="退款前状态">
              <template slot-scope="scope">
                <span>{{ getString(2, scope.row.oldCateringOrderStatus) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单状态">
              <template slot-scope="scope">
                <span :class="scope.row.oldCateringOrderStatus == 2 ? 'red' : ''">{{ scope.row.oldCateringOrderStatus == 2 ? '待退货审核' : getString(1, scope.row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="下单时间"
              prop="orderTime"
            ></el-table-column>
            <el-table-column label="已审核人">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.operatorCheckName || "" }}&nbsp;&nbsp;{{
                    scope.row.financialCheckName || ""
                  }}&nbsp;&nbsp;{{ scope.row.operatorConfirmName || "" }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" width="300">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <!-- refundStatus退款归纳状态: 0审核中 1审核通过 2审核驳回   status明细状态：0待审核 1运营通过 2运营驳回 3财务通过 4财务驳回 5运营确认通过 6运营确认不通过 -->
                  <el-button
                    @click="check(scope.row, 1)"
                    type="text"
                    v-if="
                      identityType == 1 &&
                        (scope.row.status == 0 ||
                          scope.row.status == 3 ||
                          scope.row.status == 4)
                    "
                    >审核</el-button
                  >
                  <el-button
                    @click="check(scope.row, 1)"
                    type="text"
                    v-else-if="identityType == 2 && scope.row.status == 1"
                    >审核</el-button
                  >
                  <el-button @click="check(scope.row, 2)" type="text" v-else
                    >详情</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="initData"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 审核弹窗 start -->
    <el-dialog :visible.sync="checkDialog" :title="title" @close="cancel">
      <el-steps :active="stepActive" align-center finish-status="success">
        <el-step
          :title="item.title"
          v-for="item in checkInfo"
          :key="item.title"
        ></el-step>
      </el-steps>
      <el-row>
        <div class="validate-options" v-for="(item, title) in checkInfo">
          <div>
            <label>商品退回状态：</label>
            <span>{{item.goodsReturnStatus}}</span>
          </div>
          <div>
            <label>审核意见：</label>
            <span>{{item.refundOptions}}</span>
          </div>
          <div>
            <label>退款理由：</label>
            <span>{{item.reason}}</span>
          </div>
        </div>

      </el-row>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">退款前状态：</div>
        <div class="wid-form">{{ getString(2, orderDetail.oldCateringOrderStatus) }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">订单状态：</div>
        <div class="wid-form">{{ getString(1, orderDetail.status) }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">退款单号：</div>
        <div class="wid-form">{{ orderDetail.refundCode }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">订单编号：</div>
        <div class="wid-form">{{ orderDetail.cateringOrderCode }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">客户昵称：</div>
        <div class="wid-form">{{ orderDetail.userName }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">订单信息：</div>
      </div>
      <div class="mt-24 c-6 ml-20">
        <el-table :data="orderDetail.goodsList" border class="good-wrapper">
          <el-table-column label="商品名称" prop="goodsName"></el-table-column>
          <el-table-column label="商品图片">
            <template slot-scope="scope">
              <el-avatar
                :src="picsPath(scope.row.goodsPicture)"
                shape="square"
                alt="加载失败"
              ></el-avatar>
            </template>
          </el-table-column>
          <el-table-column label="单价" prop="goodsActualPrice">
            <template slot-scope="scope">
              <span>￥{{ scope.row.goodsActualPrice }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数量" prop="goodsNum"></el-table-column>
          <el-table-column label="总计" prop="totalPrice">
            <template slot-scope="scope">
              <span>￥{{ scope.row.totalPrice }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">总计：</div>
        <div class="wid-form">{{ orderDetail.refundPrice }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">退款金额：</div>
        <div class="wid-form">
          {{ orderDetail.financialPrice || orderDetail.refundPrice }}
        </div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">商品退还状态：</div>
        <div class="wid-form">{{ orderDetail.refundReason }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">审核意见：</div>
        <div class="wid-form">{{ orderDetail.refundReason }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">退款原因：</div>
        <div class="wid-form">{{ orderDetail.refundReason }}</div>
      </div>
      <div
        class="mt-24 c-6 flx-row ali-c flx-wr"
        v-if="orderDetail.refundPicture"
      >
        <div class="tl-l ml-30">
          <div
            class="demo-image__preview"
            v-if="orderDetail.refundPicture.indexOf(',') > -1"
          >
            <el-image
              style="width: 160px; height: 160px;margin:0 20px 20px 0;"
              :src="picsPath(item)"
              :preview-src-list="srcList"
              v-for="(item, index) in orderDetail.refundPicture.split(',')"
              :key="index"
            >
            </el-image>
          </div>
          <div class="demo-image__preview" v-else>
            <el-image
              style="width: 160px; height: 160px;margin:0 20px 20px 0;"
              :src="picsPath(orderDetail.refundPicture)"
              :preview-src-list="srcList"
            >
            </el-image>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <template v-if="examineOrLook == 1">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-position="right"
          label-width="100px"
        >
          <el-form-item prop="price" label="审核金额">
            <el-input
              v-model="form.price"
              placeholder="请输入审核金额"
              :disabled="form.formDisabled"
            ></el-input>
          </el-form-item>
          <el-form-item prop="goodsReturnStatus" label="商品状态">
            <el-input
                    v-model="form.goodsReturnStatus"
                    placeholder="请输入商品退回状态，是否已出货、已退回门店、已确认不影响二次销售，请详细描述。"
                    type="textarea"
                    resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item prop="refundOptions" label="审核意见">
            <el-input
                    v-model="form.refundOptions"
                    placeholder="请输入审核意见"
                    type="textarea"
                    resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reason" label="审核理由">
            <el-input
              v-model="form.reason"
              placeholder="请输入审核理由"
              type="textarea"
              resize="none"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>

      <div class="flx-row ali-c js-c mt-50" v-if="examineOrLook == 1">
        <el-button @click="confirm('form', 2)">审核拒绝</el-button>
        <el-button type="primary" @click="confirm('form', 1)"
          >审核通过</el-button
        >
      </div>
    </el-dialog>
    <!-- 审核弹窗 end -->
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { getStoreList } from "@/api/online_department_store/commodity_manage";
import {
  getRefundOrderList,
  refundExamine,
} from "@/api/transactions/order/orders_repast";
export default {
  components: { Pagination },
  data() {
    return {
      form: { reason: "", price: 0, goodsReturnStatus: "", refundOptions: "", formDisabled: false },
      rules: {
        price: [
          { required: true, message: "审核金额不能为空", trigger: "change" },
          {
            // pattern: /^[\d]+(\.[\d]{2})?$/  只能为数字,
            pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/,
            message: "大于0且最多两位小数",
            trigger: "change",
          },
        ],
        goodsReturnStatus: [
          {
            required: true,
            trigger: "blur",
            message: "商品状态不能为空",
          },
        ],
        refundOptions: [
          {
            required: true,
            trigger: "blur",
            message: "审核意见不能为空",
          },
        ],
        reason: [
          {
            required: true,
            trigger: "blur",
            message: "理由不能为空",
          },
        ],
      },
      captureObj: {},
      //搜索栏相关数据
      searchObj: {
        orderNumber: "",
        phone: null,
        storeId: null,
        orderType: null,
        refundStatus: 0,
      },
      //订单履约状态:-2已取消 -1未使用已过期 0待支付 1待使用  2已完成 3退款待审核 4微信退款中 5商家拒绝退款 6退款成功 7退款失败
      old_order_status:[
        {
          id: -2,
          title: "已取消"
        },
        {
          id: -1,
          title: "未使用已过期"
        },
        {
          id: 0,
          title: "待支付"
        },
        {
          id: 1,
          title: "待使用"
        },
        {
          id: 2,
          title: "已使用"
        },
        {
          id: 3,
          title: "退款待审核"
        },
        {
          id: 4,
          title: "微信退款中"
        },
        {
          id: 5,
          title: "商家拒绝退款"
        },
        {
          id: 6,
          title: "退款成功"
        },
        {
          id: 7,
          title: "退款失败"
        },
      ],
      // 订单状态与门店列表
      order_status: [
        {
          id: 0,
          title: "退款待审核",
        },
        {
          id: 1,
          title: "运营通过",
        },
        {
          id: 2,
          title: "运营驳回",
        },
        {
          id: 3,
          title: "财务通过",
        },
        {
          id: 4,
          title: "财务驳回",
        },
        {
          id: 5,
          title: "运营确认通过",
        },
        {
          id: 6,
          title: "运营确认不通过",
        },
      ],
      store_list: [],
      // 会员表格数据内容
      tableData: [],
      //用于分页
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      totalNum: 0,
      url: "/order-service/order/smallProgram?export=1",
      checkDialog: false,
      disabled: false,
      orderDetail: {},
      title: "审核详情",
      stepActive: -1,
      checkInfo: [
        {
          title: "运营初审",
          reason: "",
        },
        {
          title: "财务确认",
          reason: "",
        },
        {
          title: "运营终审",
          reason: "",
        },
      ],
      identityType: 1, //假定身份  运营
      examineOrLook: 1, //1是审核  2是详情
      srcList: [],
    };
  },
  mounted() {
    // console.log("userObject", this.$store.getters.userObject);
    //type 角色类型（0 平台管理员，1 运营人员，2 财务人员  3.门店管理人员）
    if (this.$store.getters.userObject.userRoleVos) {
      this.identityType = this.$store.getters.userObject.userRoleVos[0].type;
    }

    this.initData();
  },
  methods: {
    //初始化状态
    initData() {
      if (this.$store.getters.storeId) {
        this.disabled = true;
        this.searchObj.storeId = this.$store.getters.storeId;
      }

      let data = {
        storeId: this.searchObj.storeId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        orderCode: this.searchObj.orderNumber,
        phone: this.searchObj.phone,
        refundStatus: this.searchObj.refundStatus,
      };

      getRefundOrderList(data).then((res) => {
        // console.log("餐饮退款审核列表", res.data.body);
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      });
    },
    confirm(formName, status) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.form)
          // console.log(status)
          this.checkDialog = false;
          this.refund(status);
        } else {
          return false;
        }
      });
    },
    cancel() {
      try {
        this.$refs.form.resetFields();
      } catch (err) {
        // console.log(err)
      }

      this.checkDialog = false;
    },
    // 审核弹窗出现
    check(row, type) {
      // console.log("row11",row)
      this.checkDialog = true;
      this.orderDetail = row;
      this.examineOrLook = type;
      if (row.refundPicture) {
        let newArr = row.refundPicture.split(",").map((ele) => {
          return process.env.VUE_APP_BASE_API_IMG + "/file-service/file/" + ele;
        });
        this.srcList = newArr;
      } else {
        this.srcList = [];
      }
      // status明细状态：0待审核 1运营通过 2运营驳回 3财务通过 4财务驳回 5运营确认通过 6运营确认不通过
      if (row.status == 0 || row.status == 2 || row.status == 4) {
        this.stepActive = 0;
      } else if (row.status == 1) {
        this.stepActive = 1;
      } else if (row.status == 3 || row.status == 6) {
        this.stepActive = 2;
      } else if (row.status == 5) {
        this.stepActive = 3; //全部通过
      }

      this.checkInfo[0].reason = row.operatorRemark;
      this.checkInfo[0].goodsReturnStatus = row.operatorGoodsReturnStatus;
      this.checkInfo[0].refundOptions = row.operatorRefundOptions;
      this.checkInfo[1].reason = row.financialRemark;
      this.checkInfo[1].goodsReturnStatus = row.financialGoodsReturnStatus;
      this.checkInfo[1].refundOptions = row.financialRefundOptions
      this.checkInfo[2].reason = row.operatorConfirmRemark;
      this.checkInfo[2].goodsReturnStatus = row.operatorConfirmGoodsReturnStatus
      this.checkInfo[2].refundOptions = row.operatorConfirmRefundOptions
      this.form.price =
        this.orderDetail.financialPrice || this.orderDetail.refundPrice;
      if (
        row.status == 1 &&
        this.$store.getters.userObject.userRoleVos &&
        this.$store.getters.userObject.userRoleVos[0].type == 2
      ) {
        this.form.formDisabled = false;
      } else {
        this.form.formDisabled = true;
      }
    },
    //点击按钮 审核退款  通过还是拒绝
    refund(status) {
      // console.log("userObject",this.$store.getters.userObject)
      //status  1通过   2 拒绝
      // console.log("row",this.orderDetail)
      let approveType;
      if (this.orderDetail.status == 0 || this.orderDetail.status == 4) {
        approveType = 1;
      } else if (this.orderDetail.status == 1) {
        approveType = 2;
      } else if (this.orderDetail.status == 3) {
        approveType = 3;
      }
      let params = {
        id: this.orderDetail.id, //退款申请主键id
        approver: this.$store.getters.userObject.userId, //审核人id（用户UserID）
        approverRemark: this.form.reason, //审核备注
        approveType, //审核类型 1运营审核 2财务确认 3运营确认
        approveResult: status, //审核结果 1通过 2驳回
        approvePrice: this.form.price,
        goodsReturnStatus: this.form.goodsReturnStatus, // 商品退还状态
        refundOptions: this.form.refundOptions  // 审核意见
      };
      refundExamine(params).then((res) => {
        // console.log(res.data.body)
        this.checkDialog = false;
        this.$message({
          type: "success",
          message: "审核成功",
        });
        this.initData();
      });
    },

    // 切换tab
    changeTab(tab) {
      this.searchObj.refundStatus = tab.name;
      this.initData();
    },

    // 重置搜索条件
    reset() {
      this.searchObj = {
        orderNumber: "",
        phone: null,
        status: null,
        // storeId: null,
        orderType: null,
        refundStatus: 0,
      };
      this.initData();
    },
    // 获取订单状态与类型
    getString(type, val) {
      let temp = "";
      if (type == 1) {
        this.order_status.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      }
      if(type == 2) {
        console.log(val)
        this.old_order_status.forEach((e) => {
          if(e.id == val) {
            temp = e.title;
            console.log(temp)
          }
        });
      }
      return temp;
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
  },
};
</script>
<style lang="scss" scoped>
.txt-tip-info {
  // color: #409eff;
  cursor: pointer;
}
.capture {
  padding: 20px;
  color: #000;
}
.capture div {
  margin-bottom: 5px;
}
.captureTable {
  border-collapse: collapse;
  border-top: solid #ccc 1px;
  border-left: solid #ccc 1px;
  width: 100%;
  margin-bottom: 5px;
}
.captureTable td,
.captureTable th {
  border-right: solid #ccc 1px;
  border-bottom: solid #ccc 1px;
  text-align: center;
  height: 40px;
}
.hr {
  height: 1px;
  width: 100%;
  background: #999;
  margin-bottom: 5px;
  display: inline-block;
}
.question {
  font-size: 30px;
  color: orange;
  margin-right: 5px;
}
.title1 {
  font-size: 18px;
  color: #333;
  font-size: weight;
  margin-bottom: 5px;
}
.title2 {
  font-size: 14px;
  color: #999;
}
.width {
  margin-top: 10px;
}
.img {
  width: 160px;
  height: 160px;
  margin: 20px;
}
.good-wrapper {
  box-sizing: border-box;
  width: 100%;
}
.red {
  color: red;
}
.validate-options {
  text-align: center;
  display: inline-block;
  width: 33%;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
}
</style>
